<div class="matero-error-wrap">
  <div class="matero-error-code">{{code}}</div>
  @if (title) {
    <div class="matero-error-title">{{title}}</div>
  }
  @if (message) {
    <div class="matero-error-message">{{message}}</div>
  }
  <div>
    <a mat-raised-button color="primary" routerLink="/">Back to Home</a>
  </div>
</div>
